import { captureException } from '@sentry/nextjs'

const assignError = (maybeError: any) => {
  if (typeof maybeError === 'string') {
    return new Error(maybeError)
  }
  if (typeof maybeError === 'object') {
    const error = new Error(maybeError?.data?.message ?? maybeError?.message ?? String(maybeError))
    if (maybeError?.stack) {
      error.stack = maybeError.stack
    }
    if (maybeError?.code) {
      error.name = maybeError.code
    }
    return error
  }
  return maybeError
}

export const isUserRejected = (err) => {
  // provider user rejected error code
  return typeof err === 'object' && 'code' in err && err.code === 4001
}

export const isRevertContract = (err) => {
  try {
    return typeof err === 'object' && 'code' in err && err.error.code === -32603
  } catch {
    return false
  }
}

const ENABLED_LOG = true

export const logError = (error: Error | unknown) => {
  if (ENABLED_LOG) {
    const recursiveErr: any = error
    const message = {
      reason: recursiveErr?.reason ?? recursiveErr.data?.originalError,
      method: recursiveErr?.method,
      from: recursiveErr?.transaction?.from,
      to: recursiveErr?.transaction?.to,
    }
    if (error instanceof Error) {
      captureException(message)
    } else {
      captureException(assignError(error), error)
    }
  }
}
